<template>
  <section :id="`${anchor ? anchor : 'block-' + indexBlock}`">
    <div  class="block-quick-links container pb-4 pb-lg-5">
      <div class="row">
        <div class="col-12 px-lg-0">
          <hr class="my-0 pb-5">
        </div>
        <div class="col-md-6">
          <h2 v-if="componentData.content_quick_links_title" v-html="componentData.content_quick_links_title"></h2>
        </div>
        <div class="col-md-6">
          <ul v-if="linksItems.length > 0" class="list-group">
            <li v-for="(item, key) in linksItems" class="list-group-item" :key="key">
              <a 
              v-if="item.content_quick_links_cta_target == '_blank'"
              :href="item.content_quick_links_cta_page_link? item.content_quick_links_cta_page_link : item.content_quick_links_cta_file" 
              :target="item.content_quick_links_cta_target" 
              >
                <span class="h5 mb-0">{{ item.content_quick_links_cta_label }}</span>
                <div class="float-lg-end me-2 ms-2 d-flex"><span class="me-2 d-flex d-lg-none">{{ $t('discover') }}</span><ArrowIcon /></div>
              </a>
              <NuxtLink
                v-if="item.content_quick_links_cta_target == '_self'"
                :to="$url(item.content_quick_links_cta_page_link)" 
                :target="item.content_quick_links_cta_target"
              >
                <span class="h5 mb-0">{{ item.content_quick_links_cta_label }}</span>
                <div class="float-lg-end me-2 ms-2"><span class="me-2 d-inline-block d-lg-none">{{ $t('discover') }}</span><ArrowIcon /></div>
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
  import ArrowIcon from '../../icons/arrow.vue';
  const { $url } = useNuxtApp();
 
	const props = defineProps({
		data: Object,
		index: Number,
	});

	// data
	const componentData = props?.data?.data || {};
	const linksItems = componentData.content_quick_links_repeater;
  const anchor = props?.data?.anchor || '';
	const indexBlock = props?.index || '1';
  
	
	//console.log(' ');
 // console.log(' ');
	//console.log(' ');
	//console.log('-- quick-links --');
	//console.log('componentData: ', componentData);
</script>